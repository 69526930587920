var BasketScrollbar = (function ($) {
	/* Helpers is defined in /resources/assets/js/front/helpers.js */

    // init
	init(false);

	// public methods
	return {
		init: init,
		refresh: refresh
	};

    /* functions */

    function init(need) {
    	if (need) {
            addScrollbar();
            return;
        }
        destoryScrollbar();
    }
    function refresh(need) {
    	init(need);
    }
    function destoryScrollbar() {
        var $basket = $('#basket-sticky .basket-scroll');
        if ($basket.length) {
            $basket.mCustomScrollbar("destroy");
        }
    }
    function addScrollbar() {
        var $basket = $('#basket-sticky').find('.basket-scroll');
        $basket.mCustomScrollbar({
    	    theme: 'dark',
    	    alwaysShowScrollbar: 2,
    	    scrollbarPosition: 'outside',
    	    scrollButtons: {
    	        enable: true
    	    }
    	});
    }
})(jQuery);