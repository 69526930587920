var showDotsOnFirstEmpPrice = (function ($) {
    var emptyPriceWrapper = '.list-meal-row-price-empty';

    // init
    $(emptyPriceWrapper).each(handle);

    /**
     * Handle showing dots
     * @param  {integer} index
     * @param  {mixed} price
     * @return {void}
     */
    function handle(index, wrapper) {
        if ($(wrapper).next().length && ! $(wrapper).next().hasClass('list-meal-row-price-empty')) {
            $(wrapper).css({background: 'transparent'});
        }
    }

})(jQuery);