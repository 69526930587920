$.ajaxSetup({
    headers: {
        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
    }
});
var easyOrders = {
    init: function () {
        var me = this;
        me.chooseDeliveryOption();
        me.chooseCity();
        me.addClassActive();
        me.setProductInformations();
        me.setSideDishes();
        me.setMenusetMeals();
        me.setMenusetInformations();
        me.storeOrder();
        me.destroySession();
        me.initializePasswordReset();
        me.removeProductFromBasket();
        me.goBackCityModal();
        me.goBackMealsPart();
        me.goBackEntry();
        me.goBackProductSideDishes();
        me.finalizeOrderInit();
    },
    hideModal: function () {
        $('#div-for-modal .modal').modal('hide');
        $('.modal-backdrop').remove();
    },
    showModal: function (content, callBack) {
        $('#div-for-modal').html(content.data);
        $('#div-for-modal .modal').modal('show');

        if(typeof callBack != 'undefined'){
            callBack.call();
        }
    },
    chooseDeliveryOption: function () {
        easyOrders = this;
        $(document).on('click', '.delivery_option', function () {
            //hiding old modal with choosing delivery option
            var delivery_option = $(this).data('delivery-type');
            var from_cart = $(this).data('from-cart');
            var values = {
                'delivery_type': delivery_option,
                'from_cart': from_cart
            };
            $.ajax({
                type: 'POST',
                url: '/order/delivery',
                dataType: 'json',
                data: values,
                beforeSend: function () {
                    easyOrders.showAjaxLoading('#div-for-modal .modal-content');
                },
                complete: function () {
                    easyOrders.hideAjaxLoading();
                },
                success: function (data) {
                    easyOrders.hideModal();

                    if (data === 'stop') {
                        easyOrders.breakModals();
                        return;
                    }

                    easyOrders.showModal(data);
                },
                error: function (xhr, st) {
                    console.log('error loading Data' + st);
                }
            });
        });
    },
    chooseCity: function () {
        $(document).on('click', '.city-to-select', function () {
            $('.city-to-select').removeClass('active');
            $(this).addClass('active');
            var city_id = $(this).data('city-id');
            var from_cart = $(this).data('from-cart');
            if (city_id) {
                var values = {
                    'delivery_city': city_id,
                    'from_cart': from_cart
                };

                $.ajax({
                    type: 'PUT',
                    url: '/order/delivery/update',
                    dataType: 'json',
                    data: values,
                    beforeSend: function () {
                        easyOrders.showAjaxLoading('#div-for-modal .modal-content');
                    },
                    complete: function () {
                        easyOrders.hideAjaxLoading();
                    },
                    success: function (data) {
                        if (data.code == 200 && data.info == 'Item has been successfully added to cart ') {
                            easyOrders.hideModal();
                            easyOrders.breakModals();
                        }
                        else {

                            easyOrders.hideModal();

                            if (data === 'stop') {
                                easyOrders.breakModals();
                                return;
                            }

                            easyOrders.showModal(data);
                        }
                    }
                });

            } else {
                return false;
            }
        });
    },
    addClassActive: function () {
        $(document).on('click', '.dish-3.ico-ingredient', function () {
            $(this).toggleClass('active');
        });
        $(document).on('click', '.dish-4.add-side-dishes', function () {
            $(this).toggleClass('active');
        });
    },
    setSideDishes: function (event) {
        $(document).on('click', '.valider-side-dishes', function () {
            var dishes = $('.add-side-dishes.active').map(function () {
                return parseInt(this.getAttribute('data-id'));
            }).get();

            var values = {
                'dishes': dishes
            };

            $.ajax({
                type: 'POST',
                url: 'order/cart/product/set_side_dishes',
                dataType: 'json',
                data: values,
                beforeSend: function () {
                    easyOrders.showAjaxLoading('#div-for-modal .modal-content');
                },
                complete: function () {
                    easyOrders.hideAjaxLoading();
                },
                success: function (data) {
                    easyOrders.hideModal();
                    easyOrders.refreshBasket();
                },
                error: function (xhr, st) {
                    console.log('error loading Data ' + st);
                }
            });
        });
    },
    setProductInformations: function (event) {
        $(document).on('click', '.valider-product-info', function () {

            var values = {};

            var size = $('.size input[type=radio]:checked').val();
            var comment = $('#div-for-modal #special-instructions').val();

            var ingredients = [];
            $('#div-for-modal .ingredients input:checked').each(function () {
                ingredients.push($(this).data('id'));
            });

            values['ingredients'] = ingredients;
            values['size'] = size;
            values['comment'] = comment;

            $.ajax({
                type: 'POST',
                url: 'order/cart/product/set_informations',
                dataType: 'json',
                data: values,
                beforeSend: function () {
                    easyOrders.showAjaxLoading('#div-for-modal .modal-content');
                },
                complete: function () {
                    easyOrders.hideAjaxLoading();
                },
                success: function (data) {
                    easyOrders.hideModal();

                    if (typeof data.data != 'undefined') {
                        easyOrders.showModal(data);
                    } else {
                        easyOrders.refreshBasket();
                    }
                },
                error: function (xhr, st) {
                    console.log('error loading Data ' + st);
                }
            });
        });
    },
    refreshBasket: function () {
        $.ajax({
            type: 'GET',
            url: 'order/cart/show',
            dataType: 'json',
            success: function (data) {
                $('#basket-wrapper').html(data.data);
            },
            error: function (xhr, st) {
                console.log('error loading Data ' + st);
            }
        });
    },
    setMenusetMeals: function () {
        $(document).on('click', '.menuset-meal', function () {
            var meal_id = $(this).data('id');
            var index = $('.active .menuset-part').data('index');
            var item_id = $('.active .menuset-part').data('id');
            var values = {
                'meal_id': meal_id,
                'index': index
                        // 'id':item_id
            };

            $.ajax({
                type: 'POST',
                url: 'order/cart/menuset/' + index + '/set_meals',
                dataType: 'json',
                data: values,
                beforeSend: function () {
                    easyOrders.showAjaxLoading('#div-for-modal .modal-content');
                },
                success: function (data) {
                    if ($('#menuset-parts li').size() > index + 1) {
                        $('#menuset-part-' + index).addClass('filled');
                        easyOrders.nextMenusetMeal(index);
                    } else {
                        easyOrders.addToCart();
                    }
                },
                error: function (xhr, st) {
                    easyOrders.hideAjaxLoading();
                    console.log('setMenusetMeals - order/cart/menuset/set_meals -  error' + st);
                }
            });

        });
    },
    getMenusetInformations: function (index, info, values) {
        console.log('setMenusetMeals - order/cart/menuset/set_meals -  success');
        $.ajax({
            type: 'GET',
            url: 'order/cart/menuset/' + index + '/get_informations',
            dataType: 'json',
            data: values,
            complete: function () {
                easyOrders.hideAjaxLoading();
            },
            success: function (data) {
                console.log('setMenusetMeals - order/cart/menuset/get_ingredients -  success');
                $('#div-for-modal .modal-body #menuset-body').html(data.data);
                $('#menuset-part-' + index).find('span').remove();
                $('#menuset-part-' + index).append('<span class="menuset-meal-name">' + info.selected_meal_name + '</span>');
            },
            error: function (xhr, st) {
                console.log('setMenusetMeals - order/cart/menuset/get_ingredients -  error' + st);
            }
        });
    },
    setMenusetInformations: function (event) {
        $(document).on('click', '.valider-menuset-info', function () {

            var parent = $(this).parents('.menuset-meal-info');
            var index = $(parent).data('index');
            var meal_id = $(parent).data('meal-id');

            var ingredients = [];
            $('#div-for-modal .ingredients input:checked').each(function () {
                ingredients.push($(this).data('id'));
            });

            var values = {
                'meal_id': meal_id,
                'index': index,
                'comment': $(parent).find('#meal-comment').val(),
                'removed': [],
                'added': ingredients
            };

            $.ajax({
                type: 'POST',
                url: 'order/cart/menuset/' + index + '/set_informations',
                dataType: 'json',
                data: values,
                beforeSend: function () {
                    easyOrders.showAjaxLoading('#div-for-modal .modal-content');
                },
                success: function (data) {
                    if ($('#menuset-parts li').size() > index + 1) {
                        $('#menuset-part-' + index).addClass('filled');
                        easyOrders.nextMenusetMeal(index);
                    } else {
                        easyOrders.addToCart();
                    }
                },
                error: function (xhr, st) {
                    easyOrders.hideAjaxLoading();
                    console.log('error loading Data ' + st);
                }
            });
        });
    },
    nextMenusetMeal: function (current_index) {
        var values = {};
        var index = current_index + 1;

        $.ajax({
            type: 'GET',
            url: 'order/cart/menuset/' + index + '/get_meals',
            dataType: 'json',
            data: values,
            complete: function () {
                easyOrders.hideAjaxLoading();
            },
            success: function (data) {
                $('#menuset-parts .active').removeClass('active');
                $('#menuset-parts #menuset-part-' + index).addClass('active');
                $('#div-for-modal .modal-body #menuset-body').html(data.data);
            },
            error: function (xhr, st) {
                console.log('error loading Data ' + st);
            }
        });
    },
    addToCart: function (current_index) {
        var values = {};
        var index = current_index + 1;

        $.ajax({
            type: 'GET',
            url: 'order/cart/add_to_cart',
            dataType: 'json',
            data: values,
            beforeSend: function () {
                easyOrders.showAjaxLoading('#div-for-modal .modal-content');
            },
            complete: function () {
                easyOrders.hideAjaxLoading();
            },
            success: function (data) {
                easyOrders.hideModal();
                easyOrders.refreshBasket();
            },
            error: function (xhr, st) {
                console.log('error loading Data ' + st);
            }
        });
    },
    removeProductFromBasket: function() {
        $(document).on('click', '.fa-remove.remove-product', function () {
            var index = $(this).data('product-index');
            var confirm_delete = confirm('Do you want to delete this product?');
            if (confirm_delete) {
                var values = {};
                $.ajax({
                    type: 'DELETE',
                    url: '/order/cart/remove_product/' + index,
                    dataType: 'json',
                    data: values,
                    beforeSend: function () {
                        easyOrders.showAjaxLoading('#basket-sticky');
                    },
                    complete: function () {
                        easyOrders.hideAjaxLoading();
                    },
                    success: function (data) {
                        easyOrders.refreshBasket();
                    },
                    error: function(xhr, st) {
                        console.log('error loading Data ' + st);
                        easyOrders.refreshBasket();
                    }
                });
            }
        });
    },

    //temp function for testing - clearing session
    destroySession: function () {
        var orders = this;
        $(document).on('click', '#clear-basket', function () {
            orders.callDestroySession();
        });
    },
    callDestroySession: function () {
        var values = {};
        $.ajax({
            type: 'DELETE',
            url: '/order/cart/destroy',
            dataType: 'json',
            data: values,
            beforeSend: function () {
                easyOrders.showAjaxLoading('#basket-sticky');
            },
            complete: function () {
                easyOrders.hideAjaxLoading();
            },
            success: function (data) {
                easyOrders.refreshBasket();
            },
            error: function (xhr, st) {
                console.log('error loading Data ' + st);
            }
        });
    },
    storeOrder: function () {
        $(document).on('click', '#finalize-order', function () {
            var values = {};
            var path = window.location.href.split('#');
            if (path[0].indexOf('?') !== -1) {
                var token = path[0].split('?');
                token = token[1].split('=');
                values.token = token[1];
            }

            $.ajax({
                type: 'GET',
                url: 'order/order/create',
                dataType: 'json',
                data: values,
                beforeSend: function () {
                    easyOrders.showAjaxLoading('#basket-sticky');
                },
                complete: function () {
                    easyOrders.hideAjaxLoading();
                },
                success: function (data) {
                    if(!data.login) {
                        console.log('User is logged - show checkout screens');
                        easyOrders.showModal(data);

                        $(".phone-number").mask('00 00 00 00 00');
                    } else {
                        console.log('NON LOGGED USER');
                        easyOrders.initializeLoginModals(data);
                    }
                },
                error: function (xhr, st) {
                    console.log('orderStore - error loading Data ' + st);
                }
            });
        });
    },
    initializeLoginModals: function(content){
        easyOrders.hideModal();
        
        $('#div-for-modal').html(content.data);

        if (content.login) {
            var auth = new Vue({
                el: '#auth-modal',

                data: {
                    modals: {
                        login: true,
                        register: false,
                        recover: false,
                        reset: false
                    },
                    loginData: {
                        id: '',
                        password: ''
                    },
                    registerData: {
                        username: '',
                        email: '',
                        phone_number: '',
                        password: '',
                        password_confirmation: '',
                    },
                    recoverData: {
                        id: ''
                    },
                    resetData: {
                        customer: '',
                        password: '',
                        password_confirmation: ''
                    }
                },

                methods: {
                    go: function (path, click) {
                        click = typeof click !== 'undefined' ? click : true;

                        for (modal in this.modals) {
                            this.modals[modal] = modal == path ? true : false;
                        }

                        if (click) {
                            $('.alert').hide();
                        }
                    },
                    login: function () {
                        var self = this;

                        $.ajax({
                            type: 'POST',
                            url: '/customer/auth/login',
                            dataType: 'json',
                            data: self.loginData,
                            success: function (data) {
                                if (data.login) {
                                    easyOrders.initializeLoginModals(data);
                                } else {
                                    easyOrders.hideModal();
                                    easyOrders.showModal(data);
                                    $(".phone-number").mask('00 00 00 00 00');
                                }
                            }
                        });
                    },
                    register: function () {
                        var self = this;

                        $.ajax({
                            type: 'POST',
                            url: '/customer/auth/register',
                            dataType: 'json',
                            data: self.registerData,
                            success: function (data) {
                                easyOrders.initializeLoginModals(data);
                            }
                        });
                    },
                    recover: function () {
                        var self = this;

                        $.ajax({
                            type: 'POST',
                            url: '/customer/auth/password-reset',
                            dataType: 'json',
                            data: self.recoverData,
                            success: function (data) {
                                easyOrders.initializeLoginModals(data);
                            }
                        });
                    },
                    reset: function () {
                        var self = this;

                        $.ajax({
                            type: 'POST',
                            url: '/customer/auth/password-reset/update',
                            dataType: 'json',
                            data: self.resetData,
                            success: function (data) {
                                easyOrders.initializeLoginModals(data);
                            }
                        });
                    },
                    initialize: function(path){
                        this.go(path, false);
                        $('#auth-modal').modal();
                    }
                },
                ready: function () {
                    $("#phone-number").mask('00 00 00 00 00');
                }
            });

            auth.initialize(content.path);
        } else {
            console.log('You\'re logged');
            //call further modal
            // $('#test-modal').modal();
        }
    },

    showAjaxLoading: function (element) {
        // console.log('ajax spinner called');
        $(element).append('<div class="modal-spinner"><i class="glyphicon glyphicon-refresh glyphicon-spin "></i></div>');
    },
    hideAjaxLoading: function () {
        $('.modal-spinner').remove();
    },
    initializePasswordReset: function () {
        var path = window.location.href.split('#');
        if (path.length > 1 &&  path[1].indexOf('reset') !== -1) {
            $(document).find('#finalize-order').trigger('click');
        }
    },
    goBackCityModal: function() {
        $(document).on('click', '.go-back-delivery', function () {
            var values = {};
            $.ajax({
                type: 'GET',
                url: '/order/delivery/create',
                dataType: 'json',
                data: values,
                beforeSend: function () {
                    easyOrders.showAjaxLoading('#div-for-modal .modal-content');
                },
                success: function (data) {
                    easyOrders.hideModal();
                    easyOrders.showModal(data);
                },
                error: function (xhr, st) {
                    console.log('error loading Data' + st);
                }
            });
        });
    },
    changeMenusetModalBody:function(index, values) {
        $.ajax({
            type: 'GET',
            url: 'order/cart/menuset/' + index + '/get_meals',
            dataType: 'json',
            data: values,
            beforeSend: function () {
                easyOrders.showAjaxLoading('#div-for-modal .modal-content');
            },
            complete: function () {
                easyOrders.hideAjaxLoading();
            },
            success: function (data) {
                console.log(data);
                $('#menuset-parts .active').removeClass('active');
                $('#menuset-parts #menuset-part-' + index).addClass('active');
                $('#div-for-modal .modal-body #menuset-body').html(data.data);
            },
            error: function (xhr, st) {
                console.log('error loading Data ' + st);
            }
        });
    },
    goBackMealsPart: function() {
        $(document).on('click', '.go-back-meals-part', function() {
            var index = $(this).data('index');
            var values = {};
            easyOrders.changeMenusetModalBody(index, values);
        });
    },
    goBackEntry: function() {
        $(document).on('click', '.menuset-part', function() {
            var index = $(this).data('index');
            var values = {};

            if(easyOrders.partAvailable($(this))) {
                easyOrders.changeMenusetModalBody(index, values);
            }
        });
    },
    partAvailable: function(index){
        //get the current active index. we can back only to parts already set
        // - don't allow to go to "next" parts, if we dont have previous set
        // var current_index = $('#menuset-parts .active a').data('index');
        //

        var filled = index.parent().prev();
        var el_index = filled.index();

        if(el_index == -1 || filled.hasClass('filled')) {
            return true;
        }

        return false;
    },
    goBackProductSideDishes: function() {
        $(document).on('click', '.go-back-side-dishes', function() {
            var meal_id = $(this).data('meal-id');
            var type = $(this).data('type');
            var values = {
                'type': type,
                'id': meal_id,
                'quantity': 1
            };
            $.ajax({
                type: 'GET',
                url: 'order/cart',
                dataType: 'json',
                data: values,
                beforeSend: function () {
                    easyOrders.showAjaxLoading('#div-for-modal .modal-content');
                },
                complete: function () {
                    easyOrders.hideAjaxLoading();
                },
                success: function (data) {
                    easyOrders.hideModal();
                    easyOrders.showModal(data);
                },
                error: function (xhr, st) {
                    console.log('error loading Data ' + st);
                }
            });
        });
    },
    breakModals: function () {
        easyOrders.refreshBasket();

        setTimeout(function () {
            $('#slide-basket').trigger('click');
        }, 500);
    },
    finalizeOrderInit: function(){
        var me = this;

        me.finalizeOrderEvents();
    },
    finalizeOrderEvents: function(){
        var me = this;

        $(document).on('click', '#finalize-order-button', function(e){
            e.preventDefault();
            
            me.AjaxFormSender($('#finalize-order-form'));
        });

        $(document).on('click', '#asap-checkbox', function(e){
            if($(this).is(':checked')){
                $('.service_finalize_schedule').val('');
                $('.service_finalize_date, .service_finalize_schedule').attr('disabled', 'disabled');
            }
            else{
                $('.service_finalize_date, .service_finalize_schedule').removeAttr('disabled');
            }
            
        });

    },
    AjaxFormSender: function($form, func){
        var vals = {}, $elms = $form.find('input[name],select[name],textarea[name]'), url = $form.attr('action'), method = $form.attr('method');

        $elms.each(function(){
            if($(this).attr('type') == 'checkbox' || $(this).attr('type') == 'radio'){
                if($(this).is(':checked')){
                    if(String($(this).attr('name')).match(/\[\]$/gi)){
                        if(typeof vals[$(this).attr('name')] != 'undefined'){
                            vals[$(this).attr('name')] = [$(this).val()];
                        }
                        else{
                            vals[$(this).attr('name')].push($(this).val());
                        }
                    }
                    else{
                        vals[$(this).attr('name')] = $(this).val();
                    }
                }
            }
            else{
                vals[$(this).attr('name')] = $(this).val();
            }
            
        });
        
        $.ajax({
            type: method,
            url: url,
            data: vals,
            beforeSend: function () {
                easyOrders.showAjaxLoading('#div-for-modal .modal-content');
            },
            complete: function () {
                // easyOrders.hideAjaxLoading();
            },
            success: function (data) {
                if(data.redirect != undefined){
                    location.href = data.redirect;
                }
                else if(data.data != undefined){
                    easyOrders.hideAjaxLoading();
                    easyOrders.hideModal();
                    easyOrders.showModal(data);
                    $(".phone-number").mask('00 00 00 00 00');

                    if(typeof func != 'undefined'){func.call();}

                }
            },
            error: function (xhr, st) {
                console.log('error loading Data ' + st);
            }
        });
    }
};
