var adjustNewsTitlesHeights = (function ($) {

    var dom = {
            newsTitleContainer: '#news',
            newsTitle: '.new-title'
        },
        heights = [];

    // init
    handle();

    function handle() {
        var titles = $(dom.newsTitleContainer)
            .find(dom.newsTitle);

        titles.each(function (index, element) {
            heights.push($(element).height());
        });

        var sortedHeights = heights.sort(function (a, b) {
            return b-a;
        });

        titles.each(function (index, element) {
            $(element).height(sortedHeights[0]);
        });
    }

})(jQuery);
