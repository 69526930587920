(function ($) {

    trimMenu();
    $('#restaurant-closed-modal').modal('show');
    reservation_table = document.getElementById('reservation-table');

    if (reservation_table) {
        var swipe = new Hammer(reservation_table);
        swipe.on('swiperight swipeleft', function (e) {
            e.preventDefault();
            if (e.type == 'swiperight') {
                $('#reservation-table').hide();
                $('#reservation-table').removeClass('slideInRight');
                $('#reservation-table').addClass('slideInLeft');
                $('#reservation-table').fadeIn(300);
            } else {
                $('#reservation-table').hide();
                $('#reservation-table').removeClass('slideInLeft');
                $('#reservation-table').addClass('slideInRight');
                $('#reservation-table').fadeIn(300);
            }
        });
    }

    function trimMenu()
    {
        var el = $('.toTrim');
        el.each(function () {
            var self = $(this);
            var content = self.text();
            self.text(content.trim());
        });
    }

})($);

function changeHamburgerIcon() {
    if(document.getElementById("menu-mob-ham").style.display == 'block') {
        document.getElementById("menu-mob-ham").style.display = "none";
        document.getElementById("menu-mob-x").style.display = "block";
    } else if (document.getElementById("menu-mob-ham").style.display == 'none') {
        document.getElementById("menu-mob-ham").style.display = "block";
        document.getElementById("menu-mob-x").style.display = "none";
    }
}