(function($){
    $(function(){
        var carousel_home = $('#carousel-home');
    	
    	var $carousel = carousel_home, $carousel_texts = $carousel.find('.promo-text'), $carousel_items = $carousel.find('.item'), h;
    	$carousel.css('height', '200px');
    	h = $carousel_items.eq(0).attr('style');
	    $carousel_items.css('display', 'block');

    	function manage_carousel(){

	    	$carousel_texts.each(function(index){
	    		var $p = $(this), $s = $p.children('span'), i, a;

	    		for(i=0; i<300; i+=10){
	    			$s.css('font-size', i+'px');
	    			if($s.outerWidth() >= $p.outerWidth()){
	    				break;
	    			}
	    		}

	    		if($s.outerWidth() > $p.outerWidth()){
	    			a = i - 9;
	    			for(i=1; i<=10; i++){
		    			$s.css('font-size', (i+a)+'px');
		    			if($s.outerWidth() >= $p.outerWidth()){
		    				break;
		    			}
		    		}

		    		if($s.outerWidth() > $p.outerWidth()){
		    			a = a + i - 0.9;
		    			for(i=0.1; i<=1; i+=0.1){
			    			$s.css('font-size', (i+a)+'px');
			    			if($s.outerWidth() >= $p.outerWidth()){
			    				break;
			    			}
			    		}
		    		}
	    		}
	    	});
	    	
    	}

    	manage_carousel();
    	$carousel.removeAttr('style');
	    $carousel_items.removeAttr('style');

	    if(h != undefined){
	    	$carousel_items.attr('style', h);
	    }

	    $carousel_texts.fadeTo(250,1);
	    $('.carousel').carousel();

    	$(window).resize(function(){
    		manage_carousel();
    	});
    	
    	// if there is home carousel
    	if (carousel_home.length) {
            // start carousel automatically only if there is no today's modal dialog visible
            if ($('#todays-modal-content').length) {
                carousel_home.carousel('pause');
            }
            else {
                carousel_home.carousel();
            }

            // and when today's modal is closed start it
            $('#todays-modal').on('hide.bs.modal', function (e) {
                carousel_home.carousel();
            })
        }
    });

})($);

