
$(function() {
    easyOrders.init();
    new Vue({
        el: '#ordersapp',
        data: {
            products: [],
            total: 0,
            current_product: null,
            dialogLoading: false
        },
        methods: {
            increaseAmount: function(index) {
                this.products[index].quantity++;
                this.updateTotal();
            },
            decreaseAmount: function(index) {
                if (this.products[index].quantity <= 1) {
                    var confirm_delete = confirm('Do you want to delete this product?');
                    var product_id = this.products[index].cart_id;
                    if (confirm_delete) {
                        var values = {};
                        $.ajax({
                            type: 'DELETE',
                            url: '/order/cart/remove_product/' + product_id,
                            dataType: 'json',
                            data: values,
                            success: function(data) {},
                            error: function(xhr, st) {
                                console.log('error loading Data ' + st);
                            }
                        });
                        this.products.splice(index, 1);
                        this.updateTotal();
                    }
                } else {
                    this.products[index].quantity--;
                    this.updateTotal();
                }
            },
            updateTotal: function() {
                var total = 0;
                for (var index in this.products) {
                    total += this.products[index].size_1_price * this.products[index].quantity;
                }
                this.total = total.toFixed(2);
            },
            addToBasket: function(meal_id, meal_type, event) {
                //console.log('Meal '+meal_id+' of type '+meal_type);
                //console.log('Event Meal '+$(event.target).data('meal-id')+' of type '+ $(event.target).data('type'));
                
                var values = {
                    'type': meal_type,
                    'id': meal_id,
                    'quantity': 1,
                };
                var orders_app = this;
                
                if(orders_app.dialogLoading){
                    return false;
                }
                else{
                    orders_app.dialogLoading = true;
                }

                console.log(values);

                $.ajax({
                    type: 'POST',
                    url: '/order/cart',
                    dataType: 'json',
                    data: values,
                    success: function(data) {
                        if (data.code == 200 && data.info == 'Item has been successfully added to cart ') {
                            easyOrders.refreshBasket();
                            orders_app.dialogLoading = false;
                        }
                        else {
                            $('#div-for-modal').html(data.data);
                            $('#div-for-modal .modal').modal('show');
                            orders_app.dialogLoading = false;
                        }                    
                        //if there is data.data - we have in json response html for showing modal for delivery choosing - the selecting city is in
                        //chooseDeliveryOption when user click on one of options in modal
//                        if (typeof data.data != 'undefined') {
//                           console.log('typeof data.data != undefined');
                            //$('#div-for-modal').html(data.data);
                            //$('#div-for-modal .modal').modal('show');
                            //orders_app.dialogLoading = false;
//                        } else {
//                            //data undefined so this is not first product added to basket so delivery option was set earlier
//                            console.log('addToBasket data == undefined so this is not first product added to basket so delivery option was set earlier');
//                            orders_app.current_product = data.item;
////                            orders_app.products.push(data.item);
//                            console.log('================== PRODUCTS =================');
//                            console.log(orders_app.current_product);
//                            console.log('================== PRODUCTS =================');
//                            var values = {};
//                            //this path is when there was earlier choosen delivery options ( so this is another item added to basket)
//                            //and when need_ingredients are set to true
//                            if (data.item && data.item.need_ingredients == true) {
////                                easyOrders.getIngredients(values);
//                                easyOrders.getInformations(values);
//                                // if need_ingredients is set to false we need to check if it has need_side_dishes set to true
//                            } else {
//                                console.log('addToBasket data == undefined && need_ingredients == false');
//                                //nedd_side_dishes is set to true
//                                if (data.item.need_side_dishes == true) {
////                                    easyOrders.getSideDishes(values);
//                                      easyOrders.getInformations(values);
//                                } else {
////                                    easyOrders.addToCart(values);
//                                    easyOrders.getInformations(values);
//                                }
//                            }
//                        }
                    },
                    error: function(xhr, st) {
                        console.log('error loading Data' + st);
                    }
                });
            },
        },
        ready: function() {
            //we inserting basket products from session into view throu #products-data view
            var products_data = $('#products-data').html();

            try {
                var products = JSON.parse(products_data);
                var new_products = [];
                for (var index in products) {
                    products[index].cart_id = index;
                    new_products.push(products[index]);
                }
                this.products = new_products;
                this.updateTotal();

            } catch(ex) {}
            
            test; //do not delete this
        }
    });
});