var MainMenu = (function ($) {

    // cache
    var $mainHeader = $('#main-header');
    var $menu = $('.menu-style');
   // var fixmeTop = $menu.offset().top;
    var $logo = $('#main-logo');
    var $slider = $('#carousel-home');
    var $homeAboutSection = $('#about-us');
    var menu2 = $('#menu-style-flagged');
    var $navBar = $('#navbar-collapse-1');
    var mainNav = $('#main-navbar');
    var $flagLeft = $('#menu-flag-left');
    var $flagRight = $('#menu-flag-right');
    var $menuItems = $('.full-height a');
    var $menuTriangled = $('.triangled .navbar-nav');
    var $menuTriangledEdge = $('.triangle-edge');
    var menuHeight;

    // events
    $(document).scroll(function () {
        sticky();
    });
    $(window).resize(function () {
        sticky();
        categoryMobile();
    });

    // init
    sticky();
    categoryMobile();
    hideFooterGap();
    // public methods
    return {
        sticky: sticky
    };

    // functions
    function sticky() {
        var currentScrollPosition = $(window).scrollTop();
        var fixmeTop = $menu.offset().top;
        if (windowIsScrolling(currentScrollPosition, fixmeTop)) {
            $menu.addClass('fixed-styles');
            $menu.addClass('default-row-fixed');
            setFixedStyles();
        } else {
            $menu.removeClass('fixed-styles');
            $menu.removeClass('default-row-fixed');
            setNonFixedStyles();
        }

        if (breakpoint('sm')) {
            setFixedStyles();
            $('.menu-style li:not(#reserver-button) a').css({padding: '7px 10px'});
            $menu.css({height: 50});
            if (currentPath != 'menu'){
                $('body').css({marginTop: 50});
            }else{
                var submenuHeight = $('#sub-navigation-wrapper').outerHeight() || 0;
                var bodyOffset = submenuHeight ? ( $('#sub-navigation-wrapper').position().top + $('#sub-navigation-wrapper').outerHeight() ) : ( $menu.position().top + $menu.outerHeight() );
                $('body').css({marginTop:bodyOffset});
            }
        }
        if (currentPath == 'menu') {
            if (breakpoint('sm')){
                $('#navbar-collapse-1').css({marginTop: 50});
            }else{
                $('#navbar-collapse-1').css({marginTop: 0});
            }
        }

        if (breakpoint('sm') && currentPath == 'menu' && ! menuIs('triangled')) {
            $menu.removeClass('default-row');
        }
    }

    function setFixedStyles() {
        setFixedCommonStyles();

        if(menuItemsShouldBeFull() && ! menuIs('flagged')) {
            setTimeout(function() {
                if (! breakpoint('sm')) {
                    setFullMenuItems(false);
                }
            }, 30);
        }

        if (menuIs('flagged')) {
            setFixedFlaggedStyles();
        }

        if (menuIs('triangled')) {
            setFixedTriangledStyles();
        }

        if (style.homeSlideVersion == 'full') {
            $menu.css({
                backgroundColor: style.generalMenuBackgroundColor
            });
        }
        
        if (top_menu_height > 0) {
            $('#navbar-collapse-1').css({'height': 'auto'});
        }
    }

    function setNonFixedStyles() {
        setCommonStyles();

        if(menuItemsShouldBeFull() && ! menuIs('flagged')) {
            setTimeout(function() {
                if (! breakpoint('sm')) {
                    setFullMenuItems(true);
                }
            }, 30);
        }

        if (menuIs('flagged')) {
            setFlaggedStyles();
        }

        if (menuIs('triangled')) {
            setTriangledStyles();
        }

        if (style.homeSlideVersion == 'full') {
            $menu.css({
                backgroundColor: 'transparent'
            });
        }
        if (top_menu_height > 0) {
            $('#navbar-collapse-1').css({'height': top_menu_height+'px'});
        }
    }

    function setFixedCommonStyles() {
        $menu.css({
            position: 'fixed',
            top: 0,
            left: 0,
            paddingTop: 12,
            zIndex: '1000',
            boxShadow: 'none',
            width: '100%',
        });

        if (! menuIs('triangled')) {
            $menu.addClass('default-row');
        }

        getBackground();
    }

    function setCommonStyles() {
        if (!breakpoint('sm')) {
            $menu.css({
                position: 'relative',
                paddingTop: '20px',
                boxShadow: 'none',
                height: 'auto',
            });
            $('body').css({marginTop: 0});
        }
        getBackground();
    }

    function setFixedFlaggedStyles() {
        // style is a global variable loaded in styles.blade.php
        $menu.css({
            backgroundColor: style.generalMenuBackgroundColor,
        });

        if (breakpoint('sm')) {
            $menu.find('#menu-style-flagged').removeClass('default-row').find('ul').css('height', 'auto');
        } else {
            $menu.find('#menu-style-flagged').addClass('default-row');
        }

        $flagLeft.hide();
        $flagRight.hide();
    }

    function setFlaggedStyles() {
        if (breakpoint('sm')) {
            $menu.find('#menu-style-flagged').removeClass('default-row');
        } else {
            $menu.find('#menu-style-flagged').addClass('default-row');
            $menu.find('#main-logo').css({ maxWidth: '100%' });
        }
        $flagLeft.show();
        $flagRight.show();
    }

    function setFixedTriangledStyles() {
        $logo.addClass('sticky');
        var submenuHeight = $('#sub-navigation-wrapper').outerHeight() || 0;
        if (! breakpoint('sm')) {
            $menu.css({ height: 80+submenuHeight+'px' });
            $('body').css({marginTop:80+submenuHeight});
        }else{
            var bodyOffset = submenuHeight ? ( $('#sub-navigation-wrapper').position().top + $('#sub-navigation-wrapper').outerHeight() ) : ( $menu.position().top + $menu.outerHeight() );
            $('body').css({marginTop:bodyOffset});
        }
        $menu.find('#navbar-collapse-1').css({ lineHeight: '80px' });
        // $('.traingle-edge').addClass('sticky');
        // $('.traingle-right-edge').addClass('sticky');
        getBackground();
    }

    function setTriangledStyles() {
        $logo.removeClass('sticky');
        if (! breakpoint('sm')) {
            $menu.css({ height: 'auto' });
            $('body').css({marginTop:0});   
        }else{
            
        }
        $menu.find('#navbar-collapse-1').css({ lineHeight: '80px' });
        getBackground();
        // $('.traingle-edge').removeClass('sticky');
        // $('.traingle-right-edge').removeClass('sticky');
    }

    function setFullMenuItems(fixed) {

        fixed = false || fixed;

        if (! fixed) {

            var height = $menuItems.height() / 2;

            $menuItems.css({
                padding: menuIs('triangled') ? 40 - height + 'px 12px' : (30 - height + 1) + 'px 12px ' + (30 - height + 1) + 'px 12px'
            });

            return;
        }

        menuHeight = menuHeight ? menuHeight : $menu.height();

        $menuItems.css({
            padding: ((menuHeight / 2) - $menuItems.height() / 2) + 'px 12px'
        });
    }

    function categoryMobile() {
        var width = $(window).width();

        if (breakpoint('sm')) {
            $('.flex-row').removeClass('default-row');
        } else {
            $('.flex-row').addClass('default-row');
        }

        if (menuIs('fixed-styles') && breakpoint('md')) {
            $('#menu-style-flagged__logo').css({ height: '50px'});
        }
    }

    // checkers
    function sliderIs(version) {
        return $slider.hasClass(version);
    }

    function menuIs(version) {
        return $menu.hasClass(version);
    }

    function menuItemsShouldBeFull() {
        if ($menu.is("#menu-nopadded")) {
            return false;
        }

        return $menuItems.parent().hasClass('full-height');
    }

    function windowIsScrolling(currentScrollPosition, fixmeTop) {
        var isScrolling = currentScrollPosition >= fixmeTop;
        if (currentScrollPosition==0 && fixmeTop==0){
            isScrolling =  false;
        }
        return isScrolling;
    }

    function breakpoint(value) {
        var width = $(window).width();
        var result;


        switch(value) {
            case 'sm':
                result = width < 768;
                break;
            case 'md':
                result =  width >= 768 && width < 992;
                break;
            case 'lg':
                result =  width >= 992;
                break;
        }

        return result;
    }

    // helpers
    function logoSetHeight(height) {
        $logo.css({ height: height });
    }

    function getBackground() {
        var currentScrollPosition = $(window).scrollTop();

        if (menuIs('triangled')) {
            getBackgroundForTriangledVersion();
            return;
        }

        if (style.homeSlideVersion == 'full') {
            $mainHeader.css({
                backgroundColor: 'transparent',
                height: $('#carousel-home').height()
            });
            $('.carousel-control').css({
                zIndex: 10002
            });
            $('.carousel-indicators').css({
                zIndex: 10002
            });
            return;
        }

        if (style.generalMenuBackgroundImage.value) {
            $menu.css({
                backgroundImage: 'url(' + style.generalMenuBackgroundImage.url + ')'
            });
            $mainHeader.css({
                backgroundImage: 'url(' + style.generalMenuBackgroundImage.url + ')'
            });

            return;
        }

        $menu.css({
            backgroundColor: style.generalMenuBackgroundColor
        });
    }

    function getBackgroundForTriangledVersion() {
        $menuTriangled.css({
            backgroundColor: style.generalMenuBackgroundColor
        });
        $menu.css({
            backgroundColor: style.generalMenuBackgroundColor,
        });

        if (breakpoint('md')) {
            $menu.css({
                backgroundColor: 'transparent',
            });
        }
        $mainHeader.css({
            backgroundColor: 'transparent',
            backgroundImage: 'url()'
        });
    }
    function hideFooterGap(){
        if (currentPath != 'menu'){
            $('.footer-gap').addClass("hidden");
        }
    }
})($);
