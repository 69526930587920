var Helpers = (function($) {

    $.fn.textWidth = function () {
        var text = $(this).html();
        $(this).html('<span>'+text+'</span>');
        var width = $(this).find('span:first').width();
        $(this).html(text);
        return width;
    };


	// static elelements
	var $topNav = $('.navbar-top');
	var $mainNav = $('.menu-style');
	var $footer = $('footer');
	var $frame = $(window);

	return {
		inViewport: inViewport,
		elSmallerThanWindow: elSmallerThanWindow,
		elVisibleHeight: elVisibleHeight,
		breakpoint: breakpoint
	};

	function elSmallerThanWindow($el) {
	    var freeSpace = $frame.height() - $topNav.height() - inViewport($footer) - $mainNav.height();

	    return (freeSpace - $el.height()) > 0 ? true : false;
	}

	function inViewport($el) {
	    var elH = $el.outerHeight(),
	        H   = $(window).height(),
	        r   = $el[0].getBoundingClientRect(), t=r.top, b=r.bottom;

	    return Math.max(0, t>0? Math.min(elH, H-t) : (b<H?b:H));
	}

	function elVisibleHeight($el) {
		return inViewport($el);
	}

    function breakpoint(value) {
        var width = $(window).width();
        var result;


        switch(value) {
            case 'sm':
                result = width < 768;
                break;
            case 'md':
                result =  width > 768 && width < 992;
                break;
            case 'lg':
                result =  width > 992;
                break;
        }

        return result;
    }
})($);