function Restaurant(id, token) {
	this.id = id;
	this.token = token;
}

Restaurant.prototype = {
	setOrderDatetime: function (date, time) {
		this.date = date;
		this.time = time;
	},
	validateHours: function (clousure) {
		var datetime = this.date + ' ' + this.time,
			data = {
				id: this.id,
				datetime: datetime,
				_token: this.token
			};

		$.ajax({
			method: 'POST',
			url: '/restaurant/validate/hours',
			data: data,
			success: function (data) {
				return clousure(data.result);
			}
		});
	},
	setLatestHour: function (clousure) {
		var datetime = this.date + ' ' + this.time,
			data = {
				id: this.id,
				datetime: datetime,
				_token: this.token
			};

		$.ajax({
			method: 'POST',
			url: '/restaurant/getLatest/hours',
			data: data,
			success: function (data) {
				return clousure(data.time);
			}
		});
	}
};
