var offsetSelectedCategory = (function ($) {
    // cache
    var categories = {
        triggerClassName: '.cat-x',
        markedClassName: '.marked-nav',
        navigation: '#categories-navigation'
    },
        menu = {
            className: '.menu-style'
        };

    // events
    $(categories.triggerClassName).bind('click', handle);

    /**
     * Mark selected category position
     * @return {void}
     */
    function handle() {
        var className = categories.markedClassName.substr(1);

        $(categories.markedClassName).removeClass(className);
        $(this).addClass(className);
        $(categories.navigation).collapse('hide');

        offSetScrollFromLocation($(this));
    }

    /**
     * Scroll to given category item
     * @param  {object} element
     * @return {void}
     */
     function getFinalOffset(element) {
        var href = element.attr("href");
        if (href.indexOf("#") <= -1) {
            return -1;
        }
        var destinationElement = href.substring(href.indexOf("#") + 1),
            destinationOffSet = $('#' + destinationElement).length ?  $('#' + destinationElement).offset().top : 0,
            headerHeight = getHeaderHeight();

        // console.log("destinationOffSet: " + destinationOffSet+ "  headerHeight: " + headerHeight);
        var finalOffset = destinationOffSet - headerHeight ;
        return parseInt(finalOffset);
    }
    function offSetScrollFromLocation(element) {
        var finalOffset = getFinalOffset(element);
        if (finalOffset!=-1){
            $('html, body').stop().animate({scrollTop: finalOffset}, {
                duration: 2000,
                queue : false,
                step: function( animation, fx ){
                    var newFinalOffset = getFinalOffset(element);
                    if (newFinalOffset!=-1 && fx.end != newFinalOffset){     
                        fx.start = parseInt($('html, body').scrollTop());
                        fx.end = newFinalOffset;
                    }
                }
            });
        }
    }

    /**
     * Check for allowd browsers
     * @return {boolean}
     */
    function checkAllowedBrowsers() {
        return ! /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    }

    /**
     * Get header height
     * @return {mixed}
     */
    function getHeaderHeight() {
        var headerHeight = 0,
            $menu = $(menu.className);

        if (checkAllowedBrowsers() || screen.width > 991) {

            var fixedMenuHeight = $menu.hasClass('triangled') ? 80 : 50;

            if ($menu.hasClass('fixed-styles')) {
                headerHeight = $menu.height() + 20;
            } else {
                headerHeight = $menu.height() + $('.navbar-top').height() + fixedMenuHeight;
            }

        } else if (checkAllowedBrowsers() || screen.width < 767) {
            headerHeight = $('.navigation-wrapper').height() + ($('#sub-navigation').height() - $('#categories-navigation').height()) - 20;
        }

        return headerHeight;
    }

})(jQuery);