(function($){
    if($ == 'undefined') {
        console.error('Popo-ver needed jquery.');
        return;
    }

    $.fn.popoverPosition = function() {
        var self = this;

        this.setPopoverPosition = function() {
            $(this).each(function(){

                /**
                 * Get popover content
                 * @returns {*}
                 */
                self.getContent = function() {
                    return this.find('.popover-content');
                };

                /**
                 * Get button
                 * @returns {jQuery}
                 */
                self.getButton = function() {
                    return this;
                };

                /**
                 * Get popover content height
                 * @returns {number}
                 */
                self.popoverHeight = function() {
                    var item = self.getContent();
                    return item.outerHeight(false);
                };

                /**
                 * Get popover content width
                 * @returns {number}
                 */
                self.popoverWidth = function() {
                    var item = self.getContent();
                    return item.outerWidth(false);
                };

                /**
                 * Get button content height
                 * @returns {number}
                 */
                self.buttonHeight = function() {
                    var item = self.getButton();
                    return item.outerHeight(false);
                };

                /**
                 * Get button content width
                 * @returns {number}
                 */
                self.buttonWidth = function() {
                    var item = self.getButton();
                    return item.outerWidth(false);
                };

                /**
                 * Get popover position
                 * @returns {string} - top|left
                 */
                self.popoverPosition = function() {
                    var item = self.getContent();

                    if( item.hasClass('left') ) {
                        return 'left';
                    }

                    if( item.hasClass('left') ) {
                        return 'right';
                    }
                };

                /**
                 * Get popover position
                 * @returns {string} - top|left
                 */
                self.setHorizontally = function() {
                    self.getContent().css({
                        top: - self.popoverHeight() / 2,
                        left: self.buttonWidth()
                    });
                };

                /**
                 * Get popover position
                 * @returns {string} - top|left
                 */
                self.setVertically = function() {
                    self.getContent().css({
                        top: 45
                    });
                };

                /**
                 * __constructor
                 */
                if( self.popoverPosition() == 'left' ) {
                    self.setHorizontally();
                } else {
                    self.setVertically();
                }

            });
        };

        $(window).resize(function(){
            self.setPopoverPosition();
        }).trigger('resize');

        return this;
    };

    $(function(){
        $('.pop-over').popoverPosition();
    });
})(jQuery);