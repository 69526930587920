var Basket = (function($) {
    var mealGroup = $('.list-version-content');
    var elem = $('.list-version .row').first().height(); //height of header
    //for working on other pages need to check if there is na element on page
    if (mealGroup.length) {
        var basketToTop = mealGroup.offset().top;
        var toTopDistance = basketToTop + elem;
        init();
        $(document).scroll(function() {
            setCurrentScroll(false);
        });
        $(window).on('resize', function () { 
            setCurrentScroll(true);
        });
        function setCurrentScroll(force){
            var currentScroll = getCurrentScroll();
            setTopOffset(currentScroll,force);
        }
        function init() {
            $('#basket-sticky').css({
                'top': toTopDistance + 'px'
            });
            $('.basket-scroll').css({
                'height': Math.min(getBasketHeight(),getMaxBasketHeight(toTopDistance))+ 'px'
            });
            BasketScrollbar.refresh(basketNeedScroll(toTopDistance));

            var target = document.getElementById('basket-wrapper');
            var observer = new MutationObserver(function(mutations) {
                this.setCurrentScroll(true);
            });
            observer.setCurrentScroll = setCurrentScroll;
            var config = { attributes: true, childList: true, characterData: true};
            observer.observe(target, config);
            // later, you can stop observing
            //observer.disconnect();
        }
        function getMaxBasketHeight(newTop) {
            var newHeight = $(window).height() - newTop - $('#basket-title').outerHeight() - 10;
            return newHeight;
        }
        function getBasketHeight() {
            var basket_body = $('#basket-body');
            var $wrap = $("<div />").appendTo($("body"));
            $wrap.css({
                "position":   "absolute !important",
                "visibility": "hidden !important",
                "display":    "block !important",
                "width":    $('.basket-scroll').width()+"px"
            });
            $basket_body_clone = basket_body.clone();
            $basket_body_clone.css({
                "overflow-y":"visible !important",
                "display":"block",
            });
            $basket_body_clone.appendTo($wrap);
            var currentHeight = $basket_body_clone.outerHeight()+$('#basket-total').outerHeight();
            $wrap.remove();
            return currentHeight;
        }
        function basketNeedScroll(newTop) {
            var availableHeight = $(window).height() - newTop - $('#basket-title').outerHeight() - 10;
            var currentHeight = getBasketHeight();
            if (currentHeight>availableHeight){
                return true;
            } 
            return false;
        }
        function setTopOffset(currentScroll,force) {
            var currentTop = $('#basket-sticky').position() ? parseInt(($('#basket-sticky').position().top)) : 0;
            var topDist = currentTop;
            if (currentScroll >= 100 && currentScroll < 200) {
                topDist = 230
            } else if (currentScroll >= 200) {
                topDist = 130
            }
            if (currentTop!=topDist || force){
                $('#basket-sticky').css({
                    'top': topDist + 'px'
                });
                $('.basket-scroll').css({
                    'height': Math.min(getBasketHeight(),getMaxBasketHeight(topDist)) + 'px'
                });
                BasketScrollbar.refresh(basketNeedScroll(topDist));
            }
        }
        function getCurrentScroll() {
            var currentScroll = $(document).scrollTop();
            return currentScroll;
        }
    }
})($);

$(document).on('click', '#slide-basket', function () {
    $('#basket-sticky').toggleClass('slide-to-left');
});