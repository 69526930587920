var ERO_times_constraints = {

    vars : {
        user_delay: 4, // minutes
        setTime: false
    },
    data: {},

    init: function(){
        var me = this;
        
        if(typeof finalize_data != 'undefined'){

            me.data = finalize_data;
            me.data.delay.max += me.vars.user_delay;
            me.formatData();
            me.datePickerInit();
        }
    },

    datePickerInit: function(){
        var me = this, options, d = $('.service_finalize_date').val();

        options = {
            autoclose: true,
            format: "dd/mm/yyyy",
            language: 'fr-CH',
            startDate: new Date(),
            beforeShowDay: function(date){
                return me.operatingDate(date);
            }
        };

        $('.service_finalize_date')
        .datepicker(options)
        .on('changeDate', function(e){
            me.serviceDateTimeInit(e.date, false);
        }).on('show', function(e){
            if(e.date == undefined){$('.service_finalize_schedule').html('<option value="">- Choisir -</option>');}
            $('#finalize-order-form .help-text').hide();
        }).on('hide', function(e){
            if(e.date == undefined){$('.service_finalize_schedule').html('<option value="">- Choisir -</option>');}
        });

        me.vars.setTime = true;

        if(d.match(/^\d{2}\/\d{2}\/\d{4}$/gi)){

            if(!me.isToDay(d)){
                $('.service_finalize_date').datepicker('setDate', new Date(me.getDateTimestamp(d)));
            }
            else{ $('.service_finalize_date').datepicker('setDate', new Date()); }
            
        }
        else{
            $('.service_finalize_date').datepicker('setDate', new Date());
        }
        
    },

    operatingDate: function(date){
        var me = this, i;

        for(i =0; i<me.data.holidays.length; i++){
            if(me.data.holidays[i].to != null){
                if(me.data.holidays[i].from <= date.getTime() &&( me.data.holidays[i].to + 86399999) >= date.getTime()){
                    return false;
                }
            }
            else{
                if(me.data.holidays[i].from <= date.getTime()){
                    return false;
                }
            }
        }

        return undefined;
    },
    getFirstOperatingDate: function(date){
        var me = this, i, rs = null;
        
        for (i=0; i<me.data.holidays.length; i++){
            
            if(me.data.holidays[i].to != null){

                if(me.data.holidays[i].to < date.getTime() || me.data.holidays[i].from > date.getTime()){
                    rs = date;
                }
                else{
                    return me.getFirstOperatingDate(new Date(me.data.holidays[i].to + 86400000));
                }
            }
            else{
                if(date.getTime() < me.data.holidays[i].from){
                    rs = date;
                }
            }

        }
        
        if(me.data.holidays.length == 0){
            rs = date;
        }
        
        return rs;
    },

    formatData: function(){
        var me = this, rs = [], i, obj = me.data.service_hours;

        for(item in obj){

            switch(item){

                case 'sunday':

                    obj[item][0].from = (obj[item][0].from != null ? me.convertTimeToMinutes(obj[item][0].from) : null);
                    obj[item][0].to = (obj[item][0].to != null ? me.convertTimeToMinutes(obj[item][0].to) : null);
                    obj[item][1].from = (obj[item][1].from != null ? me.convertTimeToMinutes(obj[item][1].from) : null);
                    obj[item][1].to = (obj[item][1].to != null ? me.convertTimeToMinutes(obj[item][1].to) : null);

                    rs[0] = obj[item];
                    break;

                case 'monday':

                    obj[item][0].from = (obj[item][0].from != null ? me.convertTimeToMinutes(obj[item][0].from) : null);
                    obj[item][0].to = (obj[item][0].to != null ? me.convertTimeToMinutes(obj[item][0].to) : null);
                    obj[item][1].from = (obj[item][1].from != null ? me.convertTimeToMinutes(obj[item][1].from) : null);
                    obj[item][1].to = (obj[item][1].to != null ? me.convertTimeToMinutes(obj[item][1].to) : null);

                    rs[1] = obj[item];
                    break;

                case 'tuesday':

                    obj[item][0].from = (obj[item][0].from != null ? me.convertTimeToMinutes(obj[item][0].from) : null);
                    obj[item][0].to = (obj[item][0].to != null ? me.convertTimeToMinutes(obj[item][0].to) : null);
                    obj[item][1].from = (obj[item][1].from != null ? me.convertTimeToMinutes(obj[item][1].from) : null);
                    obj[item][1].to = (obj[item][1].to != null ? me.convertTimeToMinutes(obj[item][1].to) : null);

                    rs[2] = obj[item];
                    break;

                case 'wednesday':

                    obj[item][0].from = (obj[item][0].from != null ? me.convertTimeToMinutes(obj[item][0].from) : null);
                    obj[item][0].to = (obj[item][0].to != null ? me.convertTimeToMinutes(obj[item][0].to) : null);
                    obj[item][1].from = (obj[item][1].from != null ? me.convertTimeToMinutes(obj[item][1].from) : null);
                    obj[item][1].to = (obj[item][1].to != null ? me.convertTimeToMinutes(obj[item][1].to) : null);

                    rs[3] = obj[item];
                    break;

                case 'thursday':

                    obj[item][0].from = (obj[item][0].from != null ? me.convertTimeToMinutes(obj[item][0].from) : null);
                    obj[item][0].to = (obj[item][0].to != null ? me.convertTimeToMinutes(obj[item][0].to) : null);
                    obj[item][1].from = (obj[item][1].from != null ? me.convertTimeToMinutes(obj[item][1].from) : null);
                    obj[item][1].to = (obj[item][1].to != null ? me.convertTimeToMinutes(obj[item][1].to) : null);

                    rs[4] = obj[item];
                    break;

                case 'friday':

                    obj[item][0].from = (obj[item][0].from != null ? me.convertTimeToMinutes(obj[item][0].from) : null);
                    obj[item][0].to = (obj[item][0].to != null ? me.convertTimeToMinutes(obj[item][0].to) : null);
                    obj[item][1].from = (obj[item][1].from != null ? me.convertTimeToMinutes(obj[item][1].from) : null);
                    obj[item][1].to = (obj[item][1].to != null ? me.convertTimeToMinutes(obj[item][1].to) : null);

                    rs[5] = obj[item];
                    break;

                case 'saturday':

                    obj[item][0].from = (obj[item][0].from != null ? me.convertTimeToMinutes(obj[item][0].from) : null);
                    obj[item][0].to = (obj[item][0].to != null ? me.convertTimeToMinutes(obj[item][0].to) : null);
                    obj[item][1].from = (obj[item][1].from != null ? me.convertTimeToMinutes(obj[item][1].from) : null);
                    obj[item][1].to = (obj[item][1].to != null ? me.convertTimeToMinutes(obj[item][1].to) : null);

                    rs[6] = obj[item];
                    break;

            }

        }

        me.data.service_hours = rs;

        obj = me.data.holidays;

        for(i=0; i<obj.length; i++){

            if(obj[i].to !== null){
                obj[i].to = me.getDateTimestamp(obj[i].to);
            }

            obj[i].from = me.getDateTimestamp(obj[i].from);
        }

    },

    serviceDateTimeInit: function(date, setDate) {

        if (typeof date == 'undefined') {
            date = null;
        }

        if (typeof setDate == 'undefined') {
            setDate = true;
        }

        var me = this, rs= {day: null, days: null, shift: null}, i, timeRange, date = (date == null ? new Date() : date), today = new Date();

        $('.service_finalize_schedule').html('<option value="">- Choisir -</option>');
        
        date = me.getFirstOperatingDate(date);

        if(date != null){
            rs = me.getShift(date);
        }

        if(rs.day != null && rs.shift != null){

            if(rs.days != 0){
                date = me.getFirstOperatingDate(new Date(date.getTime() + rs.days * 86400000));
                $('#finalize-order-form .help-text').show();
            }
            
            if(date != null){
                if(rs.days != 0){
                    me.serviceDateTimeInit(date, setDate);
                    return;
                }
            }
            else{
                return;
            }
            
            if($('.service_finalize_date').datepicker('getDate').getTime() != date.getTime()){
                $('.service_finalize_date').datepicker('setDate', date);
            }
            
            $('.service_finalize_schedule').html(me.getTimeRangeHtml(date, rs.shift));
        }

    },

    getTimeRangeHtml: function(date, n){
        var me = this, timeRange, sHtml = '<option value="">- Choisir -</option>', i, j, select = '';

        timeRange = me.getTimeRange(date, n);
        
        if(me.vars.setTime){
            select = $('.service_finalize_schedule').attr('data-select');
            me.vars.setTime = false;
            if(!select.match(/^\d{2}:\d{2}$/gi)){ select = ''; }
        }
        
        for(i=0; i<timeRange.length; i++){

            sHtml += '<optgroup label="">';

            for (j=0; j<timeRange[i].length; j++) {
                sHtml += '<option value="'+timeRange[i][j]+'" '+ (select == timeRange[i][j] ? 'selected' : '') +'>'+timeRange[i][j]+'</option>';
            }

            sHtml += '</optgroup>';
        }

        return sHtml;
    },

    getTimeRange: function(date, n){
        var me = this, shifts = me.data.service_hours[date.getDay()], i, rs = [], start, counter, tb = [];

        start = me.getStartByDate(date);

        if(start === true){start = shifts[n].from;}

        for (i = n; i < 2; i++){

            if(shifts[i].from != null && shifts[i].to != null){

                if(shifts[i].from < shifts[i].to){

                    if(shifts[i].from <= start && shifts[i].to >= start){
                        counter = start;
                    }
                    else{
                        counter = shifts[i].from;
                    }

                    tb = [];

                    while (counter <= shifts[i].to){
                        tb.push(me.getTimeFromMinutes(counter));
                        counter += 10;
                    }
                    
                    if(tb.length){rs.push(tb);}

                }
                else{

                    if(shifts[i].from <= start && 1439 >= start){

                        counter = start;
                        tb = [];

                        while (counter <= 1439){
                            tb.push(me.getTimeFromMinutes(counter));
                            counter += 10;
                        }

                        if(tb.length){rs.push(tb);}

                        counter = 0;
                        tb = [];

                        while (counter <= shifts[i].to){
                            tb.push(me.getTimeFromMinutes(counter));
                            counter += 10;
                        }

                        if(tb.length){rs.push(tb);}

                    }
                    else if(0 <= start && shifts[i].to >= start){

                        counter = start;
                        tb = [];

                        while (counter <= shifts[i].to){
                            tb.push(me.getTimeFromMinutes(counter));
                            counter += 10;
                        }

                        if(tb.length){rs.push(tb);}

                    }
                    else if(start < shifts[i].from){
                        counter = shifts[i].from;
                        tb = [];

                        while (counter <= 1439){
                            tb.push(me.getTimeFromMinutes(counter));
                            counter += 10;
                        }

                        if(tb.length){rs.push(tb);}

                        counter = 0;
                        tb = [];

                        while (counter <= shifts[i].to){
                            tb.push(me.getTimeFromMinutes(counter));
                            counter += 10;
                        }

                        if(tb.length){rs.push(tb);}
                    }
                }
            }
        }

        return rs;
    },

    getShift: function(date) {
        var me = this, i, j, today = new Date(), dayIndex = date.getDay() , rs;

        if(date.toDateString() == today.toDateString()){
            timeInMinutes = (me.convertTimeToMinutes(date.getHours() + ':' + date.getMinutes()) + me.data.delay.max) % 1440;
        }
        else{timeInMinutes = -1;}

        rs = me.findShift(timeInMinutes, dayIndex, 6);

        if(rs.day == null){
           rs = me.findShift(timeInMinutes, 0, dayIndex - 1);
           rs.days += (7 - dayIndex);
        }
        
        return rs;
    },

    findShift: function(timeInMinutes, from, to){
        var me = this, i, j, rs = {day: null, shift: null, days: null};
        
        for (i = from; i<=to; i++){

            for (j=0; j<2; j++){

                if(me.data.service_hours[i][j].from != null && me.data.service_hours[i][j].to != null){

                    if(me.data.service_hours[i][j].from < me.data.service_hours[i][j].to){
                        
                        if(timeInMinutes >= me.data.service_hours[i][j].from && timeInMinutes <= me.data.service_hours[i][j].to){
                            rs.day = i;
                            rs.shift = j;
                            rs.days = i - from;
                            break;
                        }

                    }
                    else{
                        
                        if((timeInMinutes >= me.data.service_hours[i][j].from && timeInMinutes <= 1439) || (timeInMinutes <= me.data.service_hours[i][j].to && timeInMinutes >= 0)){
                            rs.day = i;
                            rs.shift = j;
                            rs.days = i - from;
                            break;
                        }
                    }

                }

            }

            if(rs.day != null){

                if(rs.days > 0){
                    if(me.data.service_hours[rs.day][0].from != null && me.data.service_hours[rs.day][0].to != null){
                        rs.shift = 0;
                    }
                    else if(me.data.service_hours[rs.day][1].from != null && me.data.service_hours[rs.day][1].to != null){
                        rs.shift = 1;
                    }
                }

                break;
            }
            else {

                if(me.data.service_hours[i][0].from != null && me.data.service_hours[i][0].to != null && timeInMinutes < me.data.service_hours[i][0].from){
                    rs.day = i;
                    rs.shift = 0;
                    rs.days = i - from;
                    break;
                }
                else if(me.data.service_hours[i][1].from != null && me.data.service_hours[i][1].to != null && timeInMinutes < me.data.service_hours[i][1].from){
                    rs.day = i;
                    rs.shift = 1;
                    rs.days = i - from;
                    break;
                }

            }

            if(i == from ){timeInMinutes=-1;}

        }

        return rs;
    },

    convertTimeToMinutes: function(time){
        var me = this, tb;

        tb = time.split(':');
        return parseInt(tb[0], 10) * 60 + parseInt(tb[1], 10);
    },

    getTimeFromMinutes: function(minutes){
        var me = this, hours, mins;

        mins = String(minutes % 60);
        hours = String((minutes - mins) / 60);

        return ((hours.length == 1 ? '0' : '') + hours) + ':' +((mins.length == 1 ? '0' : '') + mins)
    },

    getStartByDate: function(date){
        var me = this, min = (new Date()).getTime() + me.data.delay.max * 60000, start;

        if(date.toDateString() != (new Date()).toDateString()){
            return true;
        }
        else if(date.getTime() < min){
            date = new Date(min);
        }

        start = date.getMinutes();
        
        if(start <= 10){
            start = date.getHours() * 60 + 10;
        }
        else if(start >= 50){
            start = ((date.getHours() + 1) * 60) % 1440;
        }
        else if(!(start % 10)) {
            start = date.getHours() * 60 + start;
        }
        else{
            start = date.getHours() * 60 + (parseInt(String(start).substring(0,1), 10) + 1) * 10;
        }

        return start;
    },

    getDateTimestamp: function(date){
        var me = this, date = date.split('/');

        return (new Date(date[1] + '/' + date[0] + '/' + date[2])).getTime();
    },

    isToDay: function(d){
        var me = this, date = new Date(), dtb

        if(d.match(/^\d{2}\/\d{2}\/\d{4}$/gi)){

            dtb = d.split('/');

            if(date.getDate() == parseInt(dtb[0], 10) && date.getMonth() + 1 == parseInt(dtb[1], 10) && date.getFullYear() == parseInt(dtb[2], 10)){
                return true;
            }
        }

        return false;
    }
};
