function createCookie(name,value,days) {
    var expires = "";
    if (days) {
        var date = new Date();
        date.setTime(date.getTime() + (days*24*60*60*1000));
        expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + value + expires + "; path=/";
}

function readCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for(var i=0;i < ca.length;i++) {
        var c = ca[i];
        while (c.charAt(0)==' ') c = c.substring(1,c.length);
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
    }
    return null;
}

function createCookiesCookie() {
    createCookie('cookies_approved',1,3650);
    hideCookiesAgreement();
}

cookies_approved = readCookie('cookies_approved');

if (cookies_approved == 1) {
    hideCookiesAgreement();
}

function hideCookiesAgreement() {
    document.getElementById('cookies-agreement').style.display = 'none';
}