var CardMenu = (function ($) {

	// cache
    var $menu = $('#menu-sticky');
	var $footer = $('footer');
    var fixmeTopAside = $menu.length ? $menu.offset().top : 0;
    var menuCatHeight = $menu.find('#categories-navigation').innerHeight();

    var oldAvailableHeightMenu = 0;

	// events
    $('#sub-navigation').find('.navbar-collapse').on('shown.bs.collapse', setMobileMenuHeight);
    $(document).scroll(function () { prepareMenu(fixmeTopAside); });
    $(window).on('resize', function () { prepareMenu(fixmeTopAside); });

	// init
	init();

	// functions

	function init()
	{
        setTimeout(function () {
            $menu.find('#categories-navigation').mCustomScrollbar({
                theme: 'dark',
                scrollbarPosition: 'outside',
                scrollButtons: {
                    enable: true
                }
            });
        }, 500);

        prepareMenu();
	}

    function prepareMenu(fixmeTopAside)
    {
        setMenuHeight();
        menuSticky(fixmeTopAside);
        setMobileMenuHeight();
    }

    // TODO refactor
    function menuSticky(fixmeTopAside) {

        var container = $('.container'),
            containerOffset = container.offset(),
            containerWidth = container.outerWidth(),
            menuHeight = $('.menu-style').height(),
            menu = $('#menu-sticky'),
            windowWidth = $(window).width(),
            currentScroll = $(window).scrollTop();

        menu.css({
            'margin-top': ($('.list-version .row').outerHeight() - 30) + 'px'
        });

        if (currentScroll >= fixmeTopAside && windowWidth > 768) {
            menu.css({
                position: 'fixed',
                top: (menuHeight - 28) + 'px',
                left: containerOffset.left + 'px',
                width: (containerWidth / 4) + 'px',
                'margin-top': '0',
                'z-index': 10000
            });

            menu.siblings().css({
                position: 'relative',
                left: '25%'
            });

        } else {
            menu.css({position: 'static', 'margin-top': ($('.list-version .row').outerHeight() - 30) + 'px'});
            menu.siblings().css({position: 'static'});
        }
    }

    function setMenuHeight()
    {
        var navigation = $('#menu-sticky').find('#categories-navigation');
        if (navigation.length) {
            var positionMenu = navigation.offset().top - (window.scrollY || window.pageYOffset || document.body.scrollTop);
            var availableHeight = $(window).height() - positionMenu - Helpers.elVisibleHeight($footer);

            if (oldAvailableHeightMenu != availableHeight) {
                oldAvailableHeightMenu = availableHeight;

                var newHeight = availableHeight - 80;

                navigation.css({
                    'height': availableHeight < menuCatHeight ? newHeight + 'px' : 'auto',
                    'display': newHeight < 50 ? 'none' : 'block'
                });
            }
        }
    }

    // TODO refactor
    function setMobileMenuHeight(){
        var subnav = $('#sub-navigation');
        var windowWidth = $(window).width();
        if (windowWidth <= 768){
            var menuInnerHeight = subnav.find('.collapse').innerHeight(),
            menuHeight = $('.navigation-wrapper').innerHeight(),
            windowHeight = $(window).height(),
            availableHeight = windowHeight - menuHeight;
            subnav.css({
                'height': (
                    menuInnerHeight >= availableHeight ||
                    (availableHeight - menuInnerHeight) <= 50 ||
                    menuInnerHeight === 0 ? availableHeight + 'px' : 'auto'
                )
            });
        }else{
            subnav.css({'height': 'auto'});
        }
    }

})($);