(function ($) {

	$('#incrementVar').on('click', function(){

		 var exampleData = { 
		  oldVar : $('#resultVar').val(),
		  mealId : $('#inputId').data('id'),
		 }

		 $.ajax({
		       type: 'GET',
		       url: "/menu/increment",
		       data: exampleData,
		       dataType: "json",
               headers: {
            	'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        		},
		       success: function(result) { 

		       	console.log(result);

		       	$('#resultVar').val(result.value);
		       }
		 });

		
	});

	$('.mealModal').on('click', function(){
		var mealId = $(this).data('id');
		$('#inputId').val( mealId);
	});


})(jQuery);