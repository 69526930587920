var runTab = function (option) {

    if (typeof jQuery == 'undefined') {
        throw 'Bootstrap tab needed jQuery';
    }

    var $ = jQuery.noConflict();

    this.option = $.extend({
        tabsSelector: '.tab [data-toggle="tab"]',
        buttonActiveClass: 'active'
    }, option);

    /**
     * Get tabs nav by selector
     * @returns {HTMLElement}
     */
    this.getTab = function () {
        return $(this.option.tabsSelector);
    };

    /**
     * Asign event to tab nav button
     * @param buttons
     */
    this.eventTabOpen = function (buttons) {
        var self = this;

        /*
         * Remove class fot old active tab
         */
        //$(buttons).removeClass(this.option.buttonActiveClass);

        /*
         * Assign event
         */
        $(buttons).click(function (e) {
            e.preventDefault();

            /*
             * Show tab and add active class for button
             */
            self.showTab(this);
        })
    };

    /**
     * Asign event to tab nav button
     * @param button
     */
    this.showTab = function (button) {
        $(button)
            .tab('show');
            //.addClass(this.option.buttonActiveClass);
    };

    /**
     * __construct
     */
    var tabs = this.getTab();
    this.eventTabOpen(tabs);
};

(function ($) {
    $(function () {
        new runTab();
    });
})(jQuery);